export const LOGIT_INTERCEPTS = {
    1: -0.5583560371345107,
    2: -2.3796749466627514,
    3: -3.412720209474587,
    4: -1.471795522775822,
    5: -1.846241144089384,
    6: -2.4742495731016283,
    7: -2.3161884756742372,
    8: -2.9288229579985523,
    9: -3.9064291259184967,
    10: -3.5136678508016104,
    11: -3.101982534543632,
    12: -2.3234300786572777,
    13: -1.6345149397555048,
    14: -2.5810090397557883,
    15: 0.0,
    16: -1.2575080932562666,
    17: -1.1161330698729446,
    18: -2.2526316805397317,
    19: -0.5718721575113725,
    20: -1.5835106487017667,
};
export const LOGIT_PFA = {
    1: 0.14845279250515822,
    2: 0.2515422700234097,
    3: 0.23271014364461426,
    4: 0.1818707478291028,
    5: 0.2623442001423675,
    6: 0.2868801641390769,
    7: 0.2373390536532512,
    8: 0.28647322959740523,
    9: 0.3626913067088043,
    10: 0.21181461641894897,
    11: 0.1651085852707908,
    12: 0.21989005058169914,
    13: 0.22283490901171815,
    14: 0.2535388980945924,
    15: 0.2753211320598195,
    16: 0.18809243767379075,
    17: 0.16178181489692228,
    18: 0.18654602396509362,
    19: 0.26857537907763146,
    20: 0.27430874062620586,
};
export const LOGIT_NFA = {
    1: 0.4518252606972676,
    2: 0.3165276007501626,
    3: 0.28848062388874257,
    4: 0.5304569338817886,
    5: 0.35667912893956355,
    6: 0.37692994314041156,
    7: 0.2797848502471489,
    8: 0.29677053701207246,
    9: 0.23007787440737626,
    10: 0.38700771856494365,
    11: 0.4301772419527847,
    12: 0.4584755603742509,
    13: 0.4709286538640545,
    14: 0.3447280452291579,
    15: 0.49924263297892824,
    16: 0.42822735457206806,
    17: 0.46368710223296605,
    18: 0.45910799874136154,
    19: 0.41252740408512384,
    20: 0.37558899367137544,
};
export const LOGIT_IS_POS2 = {
    1: 0.029666199560466372,
    2: 0.08063243493928145,
    3: 0.20399140399089463,
    4: 0.29534642868034766,
    5: 0.2223240757289579,
    6: 0.22287747281026668,
    7: 0.36488296300031237,
    8: -0.02751970488637864,
    9: 0.18778238259545776,
    10: 0.42620503355449146,
    11: 0.5513851543776527,
    12: 0.32784453773337563,
    13: 0.30764141326958117,
    14: 0.249762599446207,
    15: 0.12834218291963323,
    16: 0.04449945339243117,
    17: 0.08611950993826643,
    18: 0.36657250879177633,
    19: 0.1933866625306545,
    20: 0.09488602343751726,
};
export const LOGIT_IS_POS3 = {
    1: 0.3025621674906981,
    2: 0.44967657619938345,
    3: 0.5597254402431661,
    4: 0.5985638373912994,
    5: 0.5324344776119904,
    6: 0.36306553640772316,
    7: 0.608146229162795,
    8: 0.21307410810132754,
    9: 0.5125896534417798,
    10: 0.8767154000692049,
    11: 0.6044097135935114,
    12: 0.5560549318210787,
    13: 0.5020031568981582,
    14: 0.46001794258826934,
    15: 0.4084665013574937,
    16: 0.30598390587639984,
    17: 0.2384510353863993,
    18: 0.6767391981757984,
    19: 0.5272530687104958,
    20: 0.5415622240654516,
};
export const LOGIT_IS_POS4 = {
    1: 0.4734453292397985,
    2: 0.6885409710550734,
    3: 0.7864704493748937,
    4: 0.8763143928848973,
    5: 0.7720044670840818,
    6: 0.6804918915775735,
    7: 0.8444910157534209,
    8: 0.601987894517638,
    9: 0.9951091934750801,
    10: 1.0639209249579735,
    11: 1.0732813241024188,
    12: 0.994293088529265,
    13: 0.8861820395663832,
    14: 0.7129859678662772,
    15: 0.5337117299791612,
    16: 0.6710003877915686,
    17: 0.5350463894130736,
    18: 0.9540146953821564,
    19: 0.7367618520416377,
    20: 0.8468553568115228,
};
